<template>
  <partner-admin-page-card
    pageName="Partner Admin"
    iconClass="fa fa-shopping-cart fa-lg"
  >
    <div class="">
      <div class="row">
        <div class="col-sm-3 offset-sm-9 mb-3">
          <b-input-group>
            <div class="input-group-prepend">
              <button class="btn btn-secondary" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
            <b-form-input
              type="text"
              v-model="filterText"
              placeholder="Search"
              ref="search"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <div
        class="row"
        v-for="(moduleChunk, index) in moduleChunks"
        :key="index"
      >
        <div
          class="col-sm-4"
          v-for="(module, index) in moduleChunk"
          :key="index"
        >
          <ul class="list-group">
            <li
              class="list-group-item list-group-item-dark my-2 cursor-pointer module-link"
            >
              <router-link :to="module.path" tag="div">
                {{ module.module }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-sm-6"></div>
      </div>
    </div>
  </partner-admin-page-card>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
import _ from 'lodash'

export default {
  name: 'PartnerAdmin',
  components: {},
  beforeCreate() {
    let hasPermission = store.getters['Ability/managePartnerAdminBoard']

    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  mounted() {
    this.$refs.search.$el.focus()
  },
  data() {
    return {
      filterText: null
    }
  },
  computed: {
    modulesAndUrls() {
      return [
        { module: 'Groups', path: 'groups' },
        { module: 'Mock Users', path: 'mock_users' },
        { module: 'Users', path: 'users' },
        { module: 'User Activity Report', path: 'user_activity_report' }
      ]
    },
    filteredModules() {
      if (!this.filterText) {
        return this.modulesAndUrls
      }
      return this.modulesAndUrls.filter(module => {
        return module.module
          .toLowerCase()
          .includes(this.filterText.toLowerCase())
      })
    },
    moduleChunks() {
      return _.chunk(this.filteredModules, 3)
    }
  },
  watch: {
    filterText: {
      handler: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  border: none;
  font-weight: bold;

  &:hover {
    background-color: #b9bbbe;
  }
}
</style>
